;(function () {
  'use strict'

  angular.module('ottomatikStoreManager').directive('utmBadge', UtmBadgeDirective)

  function UtmBadgeDirective() {
    var directive = {
      restrict: 'E',
      transclude: true,
      scope: {
        param: '@',
      },
      template: '<span class="small-info">UTM<br>{{ param }}</span> <span ng-transclude></span>',
    }
    return directive
  }
})()

;(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager.statistics')
    .controller('StatisticMarketingController', StatisticMarketingController)
    .service('StatisticMarketingService', StatisticMarketingService)

  function StatisticMarketingService(CONFIG, $resource) {
    var service = {
      getMarketingData: getMarketingData,
    }

    var Statistic = $resource(CONFIG.API_URL + '/statistics/:customerId/marketing')
    function getMarketingData(customerId, storeId, dateFrom, dateTo) {
      var params = { customerId, storeId, dateFrom, dateTo }
      return Statistic.query(params).$promise
    }

    return service
  }

  function StatisticMarketingController($filter, $scope, StatisticMarketingService) {
    var vm = this
    vm.load = load
    vm.draw = draw

    function reset() {
      delete vm.data
      delete vm.chart
      vm.countParams = {
        source: {},
        medium: {},
        campaign: {},
      }
      vm.sources = {
        selected: [],
        options: [],
      }
      vm.mediums = {
        selected: [],
        options: [],
      }
      vm.campaigns = {
        selected: [],
        options: [],
      }
    }

    function load(event) {
      if (vm.loading) {
        return
      }

      reset()

      var filter = $scope.filter

      vm.loading = true
      return StatisticMarketingService.getMarketingData(
        filter.customerId,
        filter.storeId,
        $filter('date')(filter.dates.from),
        $filter('date')(filter.dates.to)
      )
        .then((response) => {
          vm.data = response
          return response
        })
        .then(collectOptions)
        .finally(() => {
          vm.loading = false
        })
    }

    function collectOptions(data) {
      var sources = []
      var mediums = []
      var campaigns = []

      data.forEach((order) => {
        if (order.infos.marketingSource) {
          if (!sources.includes(order.infos.marketingSource)) {
            sources.push(order.infos.marketingSource)
            vm.countParams.source[order.infos.marketingSource] = 0
          }
          vm.countParams.source[order.infos.marketingSource]++
        }
        if (order.infos.marketingMedium) {
          if (!mediums.includes(order.infos.marketingMedium)) {
            mediums.push(order.infos.marketingMedium)
            vm.countParams.medium[order.infos.marketingMedium] = 0
          }
          vm.countParams.medium[order.infos.marketingMedium]++
        }
        if (order.infos.marketingCampaign) {
          if (!campaigns.includes(order.infos.marketingCampaign)) {
            campaigns.push(order.infos.marketingCampaign)
            vm.countParams.campaign[order.infos.marketingCampaign] = 0
          }
          vm.countParams.campaign[order.infos.marketingCampaign]++
        }
      })

      vm.sources.options = sources.sort()
      vm.mediums.options = mediums.sort()
      vm.campaigns.options = campaigns.sort()

      return data
    }

    function draw(event) {
      var filter = $scope.filter
      var withParamsFilter = Boolean(
        vm.sources.selected.length || vm.mediums.selected.length || vm.campaigns.selected.length
      )

      var dateFrom = moment(filter.dates.from)
      var dateTo = moment.min(moment().startOf('day'), moment(filter.dates.to))

      var data = new google.visualization.DataTable()
      data.addColumn('date', 'Tag')
      data.addColumn('number', 'mit Marketingdaten')
      if (withParamsFilter) {
        data.addColumn('number', 'mit den ausgewählten Parametern')
      }

      var rows = []
      var dateToRowIndex = {}
      for (var date = moment(dateFrom); date.isSameOrBefore(dateTo); date.add(1, 'day')) {
        dateToRowIndex[date.format('YYYY-MM-DD')] = rows.length
        rows.push([date.toDate(), 0])
        if (withParamsFilter) {
          rows[rows.length - 1].push(0)
        }
      }

      vm.data.forEach((_) => {
        var rowIndex = dateToRowIndex[moment(_.date).format('YYYY-MM-DD')]
        rows[rowIndex][1]++
        if (withParamsFilter && orderMatchesParams(_)) {
          rows[rowIndex][2]++
        }
      })

      data.addRows(rows)

      vm.chart = {
        type: 'ColumnChart',
        data: data,
        options: {
          animation: {
            duration: 250,
            easing: 'out',
            startup: true,
          },
          backgroundColor: 'transparent',
          focusTarget: 'category',
          fontName: 'Roboto',
          fontSize: 14,
          height: 400,
          legend: {
            position: 'top',
          },
          title: 'Anzahl Bestellungen pro Tag …',
          titleTextStyle: {
            fontSize: 16,
          },
          hAxis: {
            minorGridlines: { count: 0 },
          },
          vAxis: {
            viewWindow: { min: 0 },
          },
        },
      }
    }

    function orderMatchesParams(order) {
      return (
        (!vm.sources.selected.length || vm.sources.selected.includes(order.infos.marketingSource)) &&
        (!vm.mediums.selected.length || vm.mediums.selected.includes(order.infos.marketingMedium)) &&
        (!vm.campaigns.selected.length || vm.campaigns.selected.includes(order.infos.marketingCampaign))
      )
    }
  }
})()
